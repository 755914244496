<template>
  <div class="ibox" ref="authenticationConfiguration">
    <div class="ibox-title">
      <h4>Authentication configuration</h4>
      <div class="ibox-tools">
        <a class="collapse-link">
            <i class="fa fa-chevron-down"></i>
        </a>
      </div>
    </div>
    <div class="ibox-content" style="display:none">
      <form id="config-authentication-form" name="config-authentication-form" role="form" action="#" method="post" @submit="onFormSubmit">
        <div class="form-group">
            <label for="isLoginCaseSensitive">{{ $t('configuration.authentication.isLoginCaseSensitive') }}</label>
            <div>
              <p-check id="isLoginCaseSensitive" class="p-switch p-fill" color="success" v-model="form.isLoginCaseSensitive">&nbsp;</p-check>
            </div>
        </div>
        <div class="form-group">
            <label for="notificationAccount">{{ $t('configuration.authentication.notificationAccount') }}</label>
            <v-select :options="listEMailAccounts" v-model="form.notificationAccount" id="notificationAccount">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.notificationAccount"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
        </div>

        <button type="submit" class="btn btn-primary block full-width ladda-button" data-style="zoom-in">{{ $t('configuration.form.button') }}</button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../theme/inspinia-jquery'; 

@Component({
  components: { }
})
export default class Authentication extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly config!: any

  form = {
    notificationAccount : this.config.notificationAccount,
    isLoginCaseSensitive : this.config.isLoginCaseSensitive
  }

  listEMailAccounts:string[] = [];

  laddaSubmit:Ladda.LaddaButton|null = null;

  created() {
    this.updateListEmailAccounts();
  }

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=config-authentication-form] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);

    enableIbox(this.$refs.authenticationConfiguration as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.authenticationConfiguration as HTMLElement);
  }

  updateListEmailAccounts() {
    // We need to get the list of available email accounts for logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/utils/misc/listEmailAccounts', options).then((response:any) => {
      if(response.accounts) {  
        this.listEMailAccounts = response.accounts;
      }
    });
  }

  onFormSubmit(evt:Event) {
    evt.preventDefault();

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();

    var input = {
      ...this.form,
    }
    
    api.postAPI('/api/config/authentication/update', input, options).then((response:any) => {
      
      this.laddaSubmit!.stop();
    });
  }
}
</script>